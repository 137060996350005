import React from 'react';
import { Link } from 'react-router-dom';

const NavBar: React.FC = () => (
    <nav className="navbar is-primary is-mobile">
        <div className="navbar-brand">
            <Link to="/" className="navbar-item">
                <h1 className="title">Terrific Table Topics</h1>
            </Link>
            <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false">
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
            </a>
        </div>
        <div className="navbar-menu">
            <div className="navbar-start">
                <Link to="/" className="navbar-item">Home</Link>
                <Link to="/about" className="navbar-item">About</Link>
                <Link to="/resources" className="navbar-item">Resources</Link>
            </div>
        </div>
    </nav>
);

export default NavBar;
