import React from 'react';

const About: React.FC = () => (
    <section className="section is-mobile">
        <div className="container is-mobile">
            <h1 className="title">About Us</h1>

            <figure className="imgHalfSized">
                <img
                    src={`${process.env.PUBLIC_URL}/images/confident_public_speaker_002b.png`}
                    alt="Terrific Table Topics!"
                />
            </figure>

            <p className="pt-4 pb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla viverra.</p>
            <p className="pt-4 pb-1">Pellentesque auctor neque nec urna. Proin sapien ipsum, porta a, auctor.</p>
            <p className="pt-4 pb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vehicula feugiat ligula, eget fringilla.</p>
            <p className="py-1">Shiver me timbers spanker run a rig overhaul reef sails hornswaggle rutters Privateer loaded to the gunwalls. Sheet aye ballast Blimey loot me Barbary Coast Jack Tar poop deck. Draft Gold Road gally run a shot </p>
            <p className="pt-1 pb-5">Black jack dead men tell no tales man-of-war yawl bring a spring upon her cable parrel quarterdeck reef sails port. </p>
        </div>
    </section>
);

export default About;
