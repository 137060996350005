import React from 'react';

const Footer: React.FC = () => (
    <footer className="footer is-mobile">
        <div className="content has-text-centered">
            <p>&copy; 2024 Terrific Table Topics. All rights reserved.</p>
        </div>
    </footer>
);

export default Footer;
