import React, { useState, useEffect } from 'react';
import { quotes } from '../quotes';

const Home: React.FC = () => {
    const [randomQuote, setRandomQuote] = useState('');

    const getRandomQuote = () => {
        const randomIndex = Math.floor(Math.random() * quotes.length);
        setRandomQuote(quotes[randomIndex].quotation);
    };

    useEffect(() => {
        getRandomQuote(); // Display a random quote on page load
    }, []);

    return (
        <section className="section is-mobile">
            <div className="container is-mobile">
                <div className="box has-text-centered is-mobile">
                    <p className="title">{randomQuote}</p>
                    <button className="button is-primary is-mobile" onClick={getRandomQuote}>Get Another Quote</button>
                </div>
                <p className="py-1">
                    Shiver me timbers spanker run a rig overhaul reef sails hornswaggle rutters Privateer loaded to the gunwalls. Sheet aye ballast Blimey loot me Barbary Coast Jack Tar poop deck. Draft Gold Road gally run a shot.
                    Shiver me timbers spanker run a rig overhaul reef sails hornswaggle rutters Privateer loaded to the gunwalls. Sheet aye ballast Blimey loot me Barbary Coast Jack Tar poop deck. Draft Gold Road gally run a shot
                </p>
            </div>
        </section>
    );
};

export default Home;
