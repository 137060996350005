import React from 'react';

const Resources: React.FC = () => (
    <section className="section is-mobile">
        <div className="container is-mobile">
            <h1 className="title">Resources</h1>

            <figure className="imgHalfSized">
                <img
                    src={`${process.env.PUBLIC_URL}/images/confident_public_speaker_001b.png`}
                    alt="Terrific Table Topics!"
                />
            </figure>

            <p className="py-1">Table Topics Questions <a href="https://www.aristotlescafe.com/blog/toastmasters-table-topics/" target="_blank" rel="noopener noreferrer">Link # 1</a>.</p>

            <p className="py-1">Table Topics Questions <a href="https://dist8tm.org/wp-content/uploads/2017/08/365_Sample_Table_Topics_Questions.pdf" target="_blank" rel="noopener noreferrer">Link # 2</a>.</p>

            <p className="py-1">Table Topics Questions <a href="https://toastmasters90210.com/fun-stuff/how-to-write-table-topics-questions" target="_blank" rel="noopener noreferrer">Link # 3</a>.</p>

            <p className="py-1">Table Topics Questions <a href="https://www.badgrammarian.com/table-topics/funny-hilarious/" target="_blank" rel="noopener noreferrer">Link # 4</a>.</p>
            <br />
            <p className="pt-4 pb-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla vehicula feugiat ligula, eget fringilla.</p>
            <p className="py-1">Quisque fermentum, justo ut dapibus luctus, lectus elit faucibus purus, at efficitur est orci vitae urna.</p>
            <p className="py-1">Matey ye code of conduct take a caulk rope's end carouser swab jolly boat pirate. Scallywag tack snow parrel coffer killick grapple salmagundi Sink me. Yo-ho-ho furl killick jolly boat ahoy keelhaul lee yard bilge rat. Sail ho jury mast heave to hardtack lugger me tackle carouser man-of-war.</p>
            <p className="pt-1 pb-5">Black jack dead men tell no tales man-of-war yawl bring a spring upon her cable parrel quarterdeck reef sails port. </p>


        </div>
    </section>
);

export default Resources;
